import store from '../store';
import i18n from '../i18n';

export default {
    async refreshTokens() {
        try {
            let tries = 0;
            while (true) {
                try {
                    const res = await store.dispatch('Auth/refreshToken');
                    if (res.status === 200) {
                        return res.data;
                    }
                    throw new Error('Error refreshing token');
                } catch (error) {
                    if (tries >= 3) {
                        throw error;
                    }
                    const timeout = 2 ** tries * 1000;
                    const jitter = Math.random() * 500;
                    await new Promise((resolve) => setTimeout(resolve, timeout + jitter));
                } finally {
                    tries += 1;
                }
            }
        } catch (error) {
            console.error(error);
            store.dispatch('Auth/logoutClient', i18n.t('global.auth.sessionExpired'));
        }
    },
};
