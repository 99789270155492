<template>
    <section class="create-comments-send-buttons">
        <secondary-action-button
            v-if="commentsState.attachConversation && !attachedConversations.length"
            :class="{ disabled: commentsState.disabled || commentsState.isFullScreen || !commentsState.external }"
            @click="commentsState.emitCheckAllConversations"
            cy-data="create-comments-check-all-conversations"
        >
            {{ $t('createComments.checkAllConversations') }}
        </secondary-action-button>

        <secondary-action-button
            v-if="commentsState.attachConversation && attachedConversations.length"
            :class="{ disabled: commentsState.disabled || commentsState.isFullScreen || !commentsState.external }"
            @click="commentsState.emitUncheckAllConversation"
            cy-data="create-comments-uncheck-all-conversation"
        >
            {{ $t('createComments.uncheckAllConversation') }}
        </secondary-action-button>

        <CreateCommentsRoundButton
            :class="{ disabled: commentsState.disabled || commentsState.isFullScreen || !commentsState.external }"
            :active="Boolean(commentsState.attachConversation)"
            :iconOnly="Boolean(commentsState.collapseEditor)"
            icon="mdi-file-tree"
            toggle
            :tooltip="$t('createComments.toggleAttachConversation')"
            @update:active="commentsState.emitAttachConversation"
            cy-data="create-comments-toggle-attach-conversation"
        />

        <secondary-action-button
            v-if="!commentsState.collapseEditor && !commentsState.isSubcase"
            :class="{ disabled: commentsState.disabled || commentsState.isFullScreen }"
            @click="commentsState.emitForward"
            cy-data="create-comments-forward"
        >
            {{ $t('createComments.forward') }}
        </secondary-action-button>

        <CreateCommentsRoundButton
            v-else
            :class="{ disabled: commentsState.disabled || commentsState.isFullScreen }"
            icon="mdi-share"
            :tooltip="$t('createComments.forward')"
            :iconOnly="true"
            @click="commentsState.emitForward"
            cy-data="create-comments-forward"
        />

        <secondary-action-button
            v-if="!commentsState.collapseEditor"
            :class="{ disabled: disableSendButton || commentsState.status === 'Closed' }"
            :loading="commentsState.sendingClose"
            @click="commentsState.emitSendClose"
            cy-data="create-comments-send-close"
        >
            {{ commentsState.external ? $t('createComments.sendClose') : $t('createComments.saveClose') }}
        </secondary-action-button>

        <CreateCommentsRoundButton
            v-else
            :class="{ disabled: disableSendButton || commentsState.status === 'Closed' }"
            :tooltip="commentsState.external ? $t('createComments.sendClose') : $t('createComments.saveClose')"
            icon="mdi-close"
            :iconOnly="true"
            :loading="commentsState.sendingClose"
            @click="commentsState.emitSendClose"
            cy-data="create-comments-send-close"
        />

        <primary-action-button
            v-if="!commentsState.collapseEditor"
            :class="{ disabled: disableSendButton }"
            :loading="commentsState.sending"
            @click="commentsState.emitSend"
            cy-data="create-comments-send"
        >
            {{ commentsState.external ? $t('global.btn.send') : $t('global.btn.save') }}
        </primary-action-button>

        <CreateCommentsRoundButton
            v-else
            :class="{ disabled: disableSendButton }"
            :tooltip="commentsState.external ? $t('global.btn.send') : $t('global.btn.save')"
            icon="mdi-send"
            :iconOnly="true"
            :loading="commentsState.sending"
            @click="commentsState.emitSend"
            cy-data="create-comments-send"
        />
    </section>
</template>

<script lang="ts">
    import { defineComponent, inject, computed } from 'vue';
    import { CreateCommentsState } from '@/types/createComments.state';
    import CreateCommentsRoundButton from '@/components/Cases/CreateComments/CreateCommentsRoundButton.vue';

    export default defineComponent({
        name: 'CreateCommentsSendButtons',

        components: {
            CreateCommentsRoundButton,
        },

        setup() {
            const commentsState = inject<CreateCommentsState>('commentsState');

            if (!commentsState) {
                throw new Error('commentsState is not provided');
            }

            const attachedConversations = computed(() => {
                return commentsState.attachedConversations as unknown as number[];
            });

            const requiredToSendEmail = computed(() => {
                if (!commentsState.external) return !commentsState.content.length;

                const isMissingRecipients = !commentsState.toRecipients.length;
                const isInvalidSender = !commentsState.systemEmails.includes(commentsState.from);
                const isEmptyContent = !commentsState.content.length;

                return isMissingRecipients || isInvalidSender || isEmptyContent;
            });

            const disableSendButton = computed(() => {
                return commentsState.disabled || requiredToSendEmail.value;
            });

            return {
                commentsState,
                attachedConversations,
                disableSendButton,
            };
        },
    });
</script>

<style scoped>
    .create-comments-send-buttons {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
</style>
