<template>
    <section :class="editorClass">
        <TipTapEditor
            ref="editor"
            :enableAttachment="commentsState.external"
            :enableOutlineAttachment="commentsState.external"
            :class="{ disabled: commentsState.disabled }"
            :existingContent="computedContent"
            :textfieldColor="textFieldcolor"
            :autocomplete="commentsState.autoComplete"
            :templateLanguage="commentsState.currentQueueLanguage"
            :caseId="commentsState.caseId"
            :queueId="commentsState.queueId"
            :defaultInlineAttachments="true"
            :signature="{ Content: signatureToApply?.content }"
            :showSignature="commentsState.showSignature && commentsState.external"
            :template="commentsState.template"
            :referenceFiles="files"
            :autoGrow="commentsState.collapseEditor"
            :watchReferences="true"
            height="100%"
            @change="handleChange"
            @cursor-update="setCursor"
            cy-data="create-comments-text-editor"
        />
    </section>
</template>

<script lang="ts">
    import { defineComponent, computed, ref, inject, Ref, watch, onMounted, nextTick } from 'vue';
    import TipTapEditor from '@/components/Global/TiptapEditor.vue';
    import { CreateCommentsState, Template, FileData } from '@/types/createComments.state';

    export default defineComponent({
        name: 'TipTapWrapper',
        components: {
            TipTapEditor,
        },
        setup() {
            const commentsState = inject<CreateCommentsState>('commentsState');

            const signature = ref(null) as Ref<Template | null>;
            const signatureToApply = ref(null) as Ref<Template | null>;

            if (!commentsState) {
                throw new Error('commentsState is not provided');
            }

            const editor = ref<InstanceType<typeof TipTapEditor> | null>(null);

            const LIGHT_BLUE = '#E1F1F5';
            const LIGHT_GRAY = '#F3F3F3';

            const textFieldcolor = computed(() => {
                return commentsState.external ? LIGHT_BLUE : LIGHT_GRAY;
            });

            const computedContent = computed({
                get: () => commentsState.content || '',
                set: (newValue) => {
                    commentsState.content = newValue;
                },
            });

            watch(commentsState, async () => {
                if (commentsState.signature) {
                    if (signature.value?.id === commentsState.signature) return;

                    const signatureTemplate = await commentsState.emitGetSignatureTemplate(commentsState.signature);
                    if (signatureTemplate.result) {
                        signature.value = signatureTemplate.result;
                        signatureToApply.value = signatureTemplate.result;
                    }
                }
            });

            const files = computed<FileData[]>(() => {
                return Array.from(new Set(commentsState.files)).map((fileId: number) => ({
                    id: fileId.toString(),
                    file: {
                        contentBytes: null,
                        name: '',
                        contentId: null,
                        contentType: '',
                        size: 0,
                        isInline: false,
                        lastModifiedDateTime: '',
                        id: fileId.toString(),
                    },
                    type: 'reference',
                    loading: true,
                }));
            });

            const handleChange = async (data: { content: string; files: FileData[]; signature: Template }) => {
                commentsState.content = data.content;

                const newFileIds = new Set(data.files.map((file) => Number(file.id)));

                commentsState.files = commentsState.files.filter((fileId) => newFileIds.has(fileId));

                if (!data.files.length) return;

                const filesToUpload = data.files.filter((file) => file.type !== 'reference');

                const filesWithReferenceIds = data.files
                    .filter((file) => file.type === 'reference' && file.file.id)
                    .map((file) => Number(file.file.id));

                const uploadedFileIds = await commentsState.emitUploadNewFiles(filesToUpload, commentsState.files);

                commentsState.files = Array.from(
                    new Set([...commentsState.files, ...uploadedFileIds, ...filesWithReferenceIds])
                );
            };

            onMounted(() => {
                const position = commentsState.cursorPosition || commentsState.content.length;
                editor.value?.setFocus(position);
            });

            const setCursor = async (position: number) => {
                await nextTick();
                commentsState.cursorPosition = position;
            };

            const editorClass = computed(() => {
                return {
                    'tiptap-editor': !commentsState.collapseEditor,
                    'tiptap-editor-collapse': commentsState.collapseEditor,
                };
            });

            return {
                files,
                editor,
                computedContent,
                handleChange,
                textFieldcolor,
                commentsState,
                signature,
                signatureToApply,
                setCursor,
                editorClass,
            };
        },
    });
</script>

<style scoped>
    .tiptap-editor {
        padding: 8px 16px;
        display: flex;
        flex-direction: column;
    }

    .tiptap-editor-collapse {
        padding: 8px;
        display: flex;
        flex-direction: column;
    }

    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
</style>
